import * as React from "react";

export function ErrorFallback({ error }) {
	console.error(error);
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
		</div>
	);
}
