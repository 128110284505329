
<div class="page-background">
	<div class="page-background-overlay"></div>
</div>

<div class="inset-card-container">
	<div class="card">
		<div class="card-header">
			<img class="logo" src="./assets/images/Mapware_Logo_CMYK.png">
		</div>

		<div class="card-body">

			<div class="card-title">
				<div>{{routeMessaging.supportTitle}}</div>
			</div>
			<div class="H7Black700Semibold">
				{{routeMessaging.supportText}}
				<ng-container *ngIf="routeMessaging.showSupportLink">
					<span
							class="link"
							(click)="sendFeedback();">
						contact support
					</span>
					to receive assistance.
				</ng-container>
			</div>

			<ng-container *ngIf="routeMessaging.showSupportLink && routeMessaging.errorCode?.length">
				<div class="H7Black500Semibold support">Please provide this information with your support message: </div>
				<div class="H7Black500Semibold">{{routeMessaging.errorCode}}</div>
			</ng-container>
		</div>

		<div class="card-actions" *ngIf="routeMessaging.logoutText">
			<button (click)="logout()"
					class="logout-button mini-button"
					mat-flat-button
					color="primary">
				{{routeMessaging.logoutText}}
			</button>
		</div>

		<div class="card-actions" *ngIf="routeMessaging.homeText">
			<button (click)="returnHome()"
					mat-flat-button color="primary">
				{{routeMessaging.homeText}}
			</button>
		</div>
	</div>
</div>
