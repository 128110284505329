
/* Imports */
import { Component, OnInit } from '@angular/core';

/* Services */
import { Alert } from '../../models';
import { HttpService, AlertService } from '../../services';

@Component({
	selector: 'app-alert-directive',
	templateUrl: 'alert.directive.html'
})
export class AlertDirective implements OnInit {

	public displayFloatingBar: boolean = false;
	public alert: Alert;
	public isSimpleAlert: boolean;
	public currentDuration: number = 0;
	public timer = null;

	constructor(
		private httpService: HttpService,
		private _alertService: AlertService
	) {
	}	// End-of constructor

	ngOnInit() {
		this._alertService.getMessage().subscribe((alert: Alert) => {
			if (alert) {
				clearTimeout(this.timer);
				this.alert = alert;
				this.displayFloatingBar = true;
				this.isSimpleAlert = typeof (this.alert.message) === 'string';
				this.timer = setTimeout(() => {
					this.displayFloatingBar = false;
				}, alert.duration);
			}
		});
	}
}
