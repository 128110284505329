import { Component, OnInit, OnDestroy } from '@angular/core';
import { DownloadService } from '../../services';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-download-directive',
	templateUrl: 'download.directive.html'
})
export class DownloadDirective implements OnInit, OnDestroy {

	public displayFloatingBar = false;
	public displayText: string = "Building zip file";
	
	private sub: Subscription;

	constructor(private downloadService: DownloadService) { }

	ngOnInit() {
		this.sub = this.downloadService.getStatus().subscribe((status: string) => {
			this.displayFloatingBar = status ? true : false;
			this.displayText = status;
		});
	}

	ngOnDestroy() {
		if (this.sub) { this.sub.unsubscribe(); }
	}
}
