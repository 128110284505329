
/* Imports */
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

/* Services */
import { AuthenticationService, SessionService, TitleService } from '../../shared/services';

/* Providers */
import { GoogleProvider } from '../../shared/providers';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

	public loading: boolean = false;

	public showError_invalidInfo: boolean = false;
	public showError_toManyAttempts: boolean = false;

	public loginFormGroup: FormGroup;
	private _gprovider: any;
	public googleAuthAvailable: boolean = true;


	@ViewChild("googleLogin") googleElement: ElementRef;

	constructor(
		private _authenticationService: AuthenticationService,
		private _activedRoute: ActivatedRoute,
		private _router: Router,
		private _formBuilder: FormBuilder,
		private _changeDetectorRef: ChangeDetectorRef,
		private _ngZone: NgZone,
		private _titleService: TitleService
	) {

		this.setupLoginForm();

	}	// End-of constructor

	ngOnInit() {
		this._titleService.setTitle('Sign in');

	}	// End-of OnInit

	ngAfterViewInit() {

		this._gprovider = <GoogleProvider>this._authenticationService.getProvider(GoogleProvider.PROVIDER_ID);

		this._gprovider.customButton('my-signin2', (d) => this.googleOnSuccess(d), (d) => this.googleOnFailure(d)).catch(() => {
			this.googleAuthAvailable = false;
		});

	}	// End-of AfterViewInit

	setupLoginForm(): void {

		this.loginFormGroup = this._formBuilder.group({
			email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
			password: ['', [Validators.required, Validators.minLength(1)]],
		});

	}	// End-of setupLoginForm

	appLogin(): void {

		this._authenticationService.loginByEmail(this.loginFormGroup.value.email, this.loginFormGroup.value.password).then(rtn => {

			// Get previous url (to navigate back to)
			let previousUrl = this._activedRoute.snapshot.queryParams['returnUrl'] || 'projects';

			// Navigate back to intended view
			this._ngZone.run(() => {
				this._router.navigate([previousUrl]);
			});

			// Reset login form values
			this.loginFormGroup.reset();
			// End loading animation
			this.loading = false;
		}).catch(error => {
			this.loading = false;

			if (error) {
				if (error.status == 429) {
					this.showError_toManyAttempts = true;
				} else {
					this.showError_invalidInfo = true;
				}
				console.warn(error);
			}
		});

	}	// End-of appLogin

	googleOnClick(): void {

		// Start loading animation
		this.loading = true;

	}	// End-of googleOnClick

	private googleOnSuccess(data): void {

		// Get provider to decipher data
		let gprovider = this._authenticationService.getProvider(GoogleProvider.PROVIDER_ID);

		// Get Token
		let token = gprovider['auth'].currentUser.get().getAuthResponse(true).id_token;
		let profile = gprovider['auth'].currentUser.get().getBasicProfile();

		this._authenticationService.loginByToken(GoogleProvider.PROVIDER_ID, token).then(rtn => {

			// Get previous url (to navigate back to)
			let previousUrl = this._activedRoute.snapshot.queryParams['returnUrl'] || 'projects';

			// Navigate back to intended view
			this._ngZone.run(() => {
				this._router.navigate([previousUrl]);
			});

			// Reset login form values
			this.loginFormGroup.reset();

			// End loading animation
			this.loading = false;
		}).catch(error => {
			this.loading = false;
			if(token && profile) {
				SessionService.set("google_account_data", {
					email: profile.getEmail(),
					first_name: profile.getGivenName(),
					last_name: profile.getFamilyName(),
					token: token,
				}, true);
			}
			console.warn(error);
		});

	}	// End-of googleOnSuccess

	private googleOnFailure(data): void {

		this.loading = false;
		this._changeDetectorRef.detectChanges();
		console.error("Google Login: ", data);

	}	// End-of googleOnFailure

	getErrorMessage(formControlName: string) {
		if (this.showError_invalidInfo) { this.showError_invalidInfo = !this.showError_invalidInfo; }
		return this.loginFormGroup.get(formControlName).hasError('email') ? 'Not a valid email address.' : '';
	}	// End-of getErrorMessage

}	// End-of class LoginComponent