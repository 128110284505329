<router-outlet></router-outlet>

<ng-container *ngIf="showNotifications">
	<ng-container *ngIf="!isMobile">
		<ng-container *ngTemplateOutlet="t_notifications"></ng-container>
	</ng-container>
	<ng-container *ngIf="isMobile">
		<ng-container *ngTemplateOutlet="t_mobileNotifications"></ng-container>
	</ng-container>
</ng-container>

<div class="alert-container" id="alert-container" data-testid="alert-container">
	<ng-container *ngTemplateOutlet="t_alertNotifications"></ng-container>
</div>

<ng-template #t_notifications>

	<!-- Notifications (on process, download, and upload statuses)	-->
	<span class="notifications-container"
		  data-testid="notifications-container"
		  [ngStyle]="relativeToAlert()">
			<ng-container *ngTemplateOutlet="t_mainNotifications"></ng-container>
	</span>
</ng-template>

<ng-template #t_mobileNotifications>

	<div class="notifications-container" id="notifications-container" [ngStyle]="relativeToAlert()">

		<div class="mobile-notifications-container"
			 [ngClass]="{'closed': !mobileNotificationsOpen}"
			#ref>
			<ng-container *ngTemplateOutlet="t_mainNotifications"></ng-container>
		</div>

		<button
				*ngIf="numNotifications"
				mat-fab
				class="expand-button"
				(click)="setMobileNotifications();"
		>
			<mat-icon>{{mobileNotificationsOpen ? 'expand_more' : 'notifications'}}</mat-icon>
			<div *ngIf="showNumNotifications" class="notifications-label">{{displayNumNotifications()}}</div>
		</button>
	</div>
</ng-template>

<ng-template #t_mainNotifications>
	<app-upload-directive></app-upload-directive>
	<app-download-directive></app-download-directive>
</ng-template>

<ng-template #t_alertNotifications>
	<app-alert-directive></app-alert-directive>
</ng-template>
