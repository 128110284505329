
<div class="page-background">
	<div class="page-background-overlay"></div>
</div>

<div class="inset-card-container">
	<div class="card" [ngSwitch]="activeSlide">
		<div class="card-header">
			<img class="logo-icon" src="./assets/images/aa_logo.png">
		</div>

		<ng-container *ngSwitchCase="'recover'">
			<div class="card-body">
				<ng-container *ngTemplateOutlet="t_recover"></ng-container>
			</div>

			<div class="card-footer">
				<!-- Submit -->
				<button mat-flat-button
						color="primary"
						type="submit"
						value="Submit"
						[disabled]="!recoverPasswordFormGroup.valid"
						(click)="appRecover()">
					Recover password
				</button>
				<div class="nav-container">
					<a class="nav-link" routerLink="/register">Don’t have an account? Create an account.</a>
				</div>
			</div>
		</ng-container>

		<ng-container *ngSwitchCase="'sent'">
			<div class="card-body">
				<ng-container *ngTemplateOutlet="t_sent"></ng-container>
			</div>
		</ng-container>

		<ng-container *ngSwitchCase="'reset'">
			<div class="card-body">
				<ng-container *ngTemplateOutlet="t_reset"></ng-container>
			</div>
		</ng-container>

		<ng-container *ngSwitchCase="'changed'">
			<div class="card-body">
				<ng-container *ngTemplateOutlet="t_changed"></ng-container>
			</div>
		</ng-container>
	</div>
</div>

<ng-template #t_recover>
	<div class="card-title">
		<div class="card-sub-title">MAPWARE</div>
		<div class="H4Black700Regular">Forgot password</div>
	</div>

	<div class="forgot-info">
		Enter the email associated with your Mapware account, and we will send you instructions to recover your password.
	</div>

	<div class="forms">
		<form novalidate
			name="form"
			(ngSubmit)="appRecover()"
			[formGroup]="recoverPasswordFormGroup">

			<div class="input-header">Email address</div>
			<mat-form-field subscriptSizing="dynamic" appearance="fill">
				<input matInput
					type="email"
					name="email"
					placeholder="Enter your email"
					formControlName="email"
				>
			</mat-form-field>

			<div *ngIf="this.recoverPasswordFormGroup.invalid && email.touched" class="email-error-msg">
				Please enter a valid email address.
			</div>

			<div *ngIf="showInvalid" style="height: 22px; margin-top: 8px; margin-bottom: 10px;">
				<mat-error class="H7RedRegular">This email is not registered with Mapware. Please check the email and contact support if the issue persists.</mat-error>
			</div>
		</form>
	</div>
</ng-template>
<!-- END-OF t_reset -->

<ng-template #t_sent>
	<div class="card-title">
		<div class="card-sub-title">MAPWARE</div>
		<div>Instructions sent!</div>
	</div>

	<div class="H6Black500Regular">
		We sent an email to
		<span style="font-weight: bold;">{{recoverPasswordFormGroup.value.email}}</span>
		with instructions to recovering your password.
	</div>

	<div style="padding-top:24px">
		<button mat-stroked-button color="primary"
			value="Submit"
			(click)="goTo('login')">
			Back to Mapware
		</button>
	</div>
</ng-template>
<!-- END-OF t_set -->

<ng-template #t_reset>
	<div class="card-title">
		<div class="card-sub-title">MAPWARE</div>
		<div>Create new password</div>
	</div>

	<div class="forms">
		<form novalidate
			name="form"
			[formGroup]="resetPasswordFormGroup"
			(ngSubmit)="appReset()">

			<!-- Password -->
			<div class="input-header">New Password</div>
			<mat-form-field subscriptSizing="dynamic" appearance="fill" style="width: 100%">
				<input matInput
					data-testId="password-forgot"
					type="password"
					name="password"
					placeholder="Enter password"
					formControlName="password">
			</mat-form-field>

			<!-- Confirm Password -->
			<div class="input-header">Confirm Password</div>
			<mat-form-field subscriptSizing="dynamic" appearance="fill" style="width: 100%; margin-bottom: 32px;">
				<input matInput
					data-testId="password-forgot-confirm"
					type="password"
					name="password_verfication"
					placeholder="Enter password"
					formControlName="password_verfication">
			</mat-form-field>

			<!-- Submit -->
			<div>
				<button mat-stroked-button color="primary"
					style="width: 100%;"
					value="Submit"
					[disabled]="!resetPasswordFormGroup.valid">
					Submit
				</button>

				<input type="submit" style="display: none" />
			</div>
		</form>
	</div>
</ng-template>
<!-- END-OF t_reset -->

<ng-template #t_changed>
	<div class="card-title">
		<div class="card-sub-title">MAPWARE</div>
		<div>Password changed</div>
	</div>

	<div class="H6Black500Regular">
		You have successfully changed your password<ng-container *ngIf="recoverPasswordFormGroup.value.email"> for {{recoverPasswordFormGroup.value.email}}</ng-container>. You may now sign in to your account with your new password.
	</div>

	<div style="padding-top:24px">
		<button mat-stroked-button color="primary"
			style="width: 100%;"
			value="Submit"
			(click)="goTo('login')">
			Continue to login
		</button>
	</div>
</ng-template>
<!-- END-OF t_reset -->
