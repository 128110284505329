/* Imports */
import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import { Router, NavigationEnd } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import _, {cloneDeep} from 'lodash';
import { ConfirmationModal } from '@app/components';

import pluralize from 'pluralize';

/* Services */
import { Alert } from "../../models";
import {
	AdminService,
	SessionService,
	UploadService,
	AuthenticationService,
	OrganizationService,
	AlertService,
	SelectedProjectService,
	ProjectService, listFormatters
} from '../../services';
import {Subscription} from 'rxjs';

const handleModal = (targetModal, modalType, data, afterClose = _.noop) => {
	targetModal.open(modalType, { data }).afterClosed().subscribe(afterClose);
};

@Component({
	selector: "app-upload-directive",
	templateUrl: "upload.directive.html",
	styleUrls: ["./upload.directive.scss"],
})
export class UploadDirective implements AfterViewInit, OnDestroy {

	public displayFloatingBar: boolean = false;
	public isLoggedIn: boolean = true;

	public isUploading = false;
	public isDeveloper = false;
	public openDialog = (modalType, data, afterClose) =>
		handleModal(this._dialog, modalType, data, afterClose);

	public renderState = null;
	public uploadSubscription: Subscription;
	public uploadSubscriptionV2: Subscription;

	constructor(
		private _dialog: MatDialog,
		private _adminService: AdminService,
		private _uploadService: UploadService,
		private _router: Router,
		private _orgService: OrganizationService,
		private _authService: AuthenticationService,
		private _alertService: AlertService,
		private _selectedProject: SelectedProjectService,
		private _projectService: ProjectService
	) {
		this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const user = this._authService.user;
				this.isLoggedIn = user && user.id;
				if (this.isLoggedIn) this.makeSubscription();
			}
		});
	} // End-of constructor

	ngAfterViewInit() {
		const user = this._authService.user;
		this.isLoggedIn = user && user.id;
		this.makeSubscription();
	}

	ngOnDestroy() {
		this.uploadSubscriptionV2?.unsubscribe();
		this.uploadSubscription?.unsubscribe();
	}

	makeSubscription() {
		if (!this.uploadSubscription) {
			this.uploadSubscription = this._uploadService.projectListChange.subscribe(({renderState}) => {

				if (!this.isUploading && renderState.status.anyActive) {
					this.displayFloatingBar = true;
					const thirtySeconds = 30 * 1000;
					this._alertService.notification(
						new Alert(
							`Your files are uploading. Please don't refresh or close the page until all of your files have been uploaded.`,
							undefined,
							thirtySeconds
						)
					);
				}
				this.isUploading = renderState.status.anyActive || renderState.status.anyPaused || renderState.uploadIssue?.length;
				this.renderState = renderState;
			});
		}
	}

	openProject(proj): void {
		this._projectService.getById(proj.id).then(project => {
			SessionService.set('project_tab_index', 0);
			this._selectedProject.setSelectedProject(project);
			this._router.navigateByUrl('/projects/view/' + project.id);
		})
	}

	togglePause(projectId): void {
		this._uploadService.toggleProjectStatus(projectId);
	}

	markProjectCanceled(projectName, projectId): void {
		const confirmOptions = {
			title: `Stop project file upload?`,
			text: `Are you sure you want to stop uploading files for project ${projectName}? Files that are uploading or that have been uploaded will be available in the project.`,
			buttonText: `Stop upload`,
		};
		this.openDialog(ConfirmationModal, confirmOptions, (rtn) => {
			rtn && this._uploadService.markProjectCanceled(projectId);
		});
	}

	useProjectId = (__, p = { id: null }) => {
		return p.id
	};

	projectsWithErrors({projectList}): Array<any> {
		return projectList.filter(proj => proj.hasError)?.map(x => x.name).join(', ');
	};

	failedImagesText({failedFileNames}): string {
		const failedLength = failedFileNames.split(',').length;
		return `${failedLength} ${pluralize('file', failedLength)} failed to upload`;
	};

	projectsPaused({projectList}): string {
		const pausedProjects = projectList.filter(proj => proj.status === "PAUSED");
		return (pausedProjects.slice(0, 2).map(x => x.name).join(', '))
			+ (pausedProjects.length > 2 ? ` and ${pausedProjects.length - 2} more` : '');
	}

	getUploadErrorShortText(uploadState): string {
		if (uploadState.failedList.length) {
			if (uploadState.failedList.length > 3) {
				const firstThree = uploadState.failedList.map(x => x.name).slice(0, 3);
				const nMoreFailed = uploadState.failedList.length - firstThree.length;
				const combined = listFormatters.formatAnd(firstThree);
				return `${combined} and ${nMoreFailed} more had an issue uploading.`
			}
			const defaultCombined = listFormatters.formatAnd(uploadState.failedList.map(x => x.file?.name ?? x.name ?? "Unnamed"));
			return `${defaultCombined} had an issue uploading.`
		}
	}
}
