
<ng-container>
	<div class="page-background">
		<div class="page-background-overlay"></div>
	</div>

	<div class="inset-card-container">
		<div class="card">
			<div class="card-header login-card ">
				<img class="logo" src="./assets/images/Mapware_Logo_CMYK.png">
			</div>

			<ng-container [ngSwitch]="showVerification(verificationData, showVerifiedNoUser, showVerificationError)">
				<ng-container *ngSwitchCase="verificationOptions.notVerified">
					<ng-container *ngTemplateOutlet="t_emailVerification"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="verificationOptions.verified">
					<ng-container *ngTemplateOutlet="t_verified"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="verificationOptions.verificationError">
					<ng-container *ngTemplateOutlet="t_verificationError"></ng-container>
				</ng-container>
			</ng-container>
		</div>
	</div>
</ng-container>

<ng-template #t_emailVerification>
	<div class="card-body">
		<div class="card-title centered">
			Almost there! Please verify your email to secure your account.
		</div>

		<div class="centered H7Black700Regular">
			Thank you for signing up!
			<br><br>
			To continue, click “Activate My Account” in the verification email sent to
			<div class="user-email" *ngIf="user?.email">{{user.email}}.</div>
			<ng-container *ngIf="!user">your email.</ng-container>
		</div>
	</div>

	<div class="card-footer">

		<div class="card-actions">
			<button
					mat-stroked-button color="primary"
					class="main-card-button"
					(click)="resendEmail()">
				Resend verification email
			</button>
		</div>

		<div class="card-actions">
			<button
					mat-button
					class="nav-link"
					(click)="logout()">
				Log out
			</button>
		</div>
	</div>
</ng-template>

<ng-template #t_verified>

	<div class="card-body verification-container">

		<div class="card-title centered bold">
			Congratulations!
		</div>

		<div class="H5Black700 welcome-message">
			Your new Mapware account has been verified.
		</div>

		<ng-container *ngTemplateOutlet="t_planDetails"></ng-container>
	</div>

	<div class="card-footer">

		<div class="card-actions H7Black500Regular italic" *ngIf="!user">
			Your account is already verified, please log in to use Mapware.
		</div>

		<div class="card-actions">
			<button
					mat-stroked-button color="primary"
					class="main-card-button"
					(click)="navigateToMapware()">
				{{user ? "Get Started" : "Login to Mapware"}}
			</button>
		</div>

		<div class="card-actions" *ngIf="user">
			<button
					mat-button
					class="nav-link"
					(click)="logout()">
				Log out
			</button>
		</div>
	</div>
</ng-template>

<ng-template #t_verificationError>

	<div class="card-body">

		<div class="card-title centered">
			Something went wrong.
		</div>

		<div class="centered H7Black700Regular">
			We couldn't verify your account, please log in and then check your email again.
			<br><br>
			If the issue persists please
			<span class="support-link">contact support</span>.
		</div>
	</div>

	<div class="card-footer">
		<button
				mat-button
				class="nav-link"
				(click)="navigateToMapware()">
			Go back to {{ homeUrl }}
		</button>
	</div>
</ng-template>

<ng-template #t_planDetails>
	<div class="plan-details">
		<div class="H5Black700" style="margin-bottom: 2rem; margin-top: 1rem;">Your 15-day free trial includes:</div>
		<div class="feature-list">
			<div>
				<mat-icon class="feature-check">
					done
				</mat-icon>
				{{freeCredits | comma}} credits to get you started
			</div>
			<div>
				<mat-icon class="feature-check">
					done
				</mat-icon>
				1 User
			</div>
			<div>
				<mat-icon class="feature-check">
					done
				</mat-icon>
				10GB of storage
			</div>
			<div class="bold">During and after your free trial, you can upgrade to a subscription or pay-as-you-go plan.</div>
		</div>
	</div>
</ng-template>
