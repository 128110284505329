
/* Imports */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {startReact} from "@app/react/App";
import {isEqual} from "lodash";

declare global {
	interface Console {
		memo: (v: unknown) => typeof v;
		_memoPrev: unknown;
		tap: (v: unknown, ...rest: unknown[]) => typeof v;
	}
}
console.tap = (v, ...rest) => ( console.log(v, ...rest), v )

console.memo = (v) => {
	if(!isEqual(v, console._memoPrev)) {
		console.log(v)
		console._memoPrev = v;
	}
	return v
}

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err))
	.then(() => {
		startReact();
	});
