import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '@shared/services/alert.service';
import pathmatch from 'path-match';
import {Router} from "@angular/router";
import {GlobalService} from "@shared/services";

const createRouteMatcher = pathmatch();

/**
 * Creates matching functions that will work for arrays or strings/numbers
 *
 * @param {string|number|string[]|number[]} subject
 * @returns {function(string|number): boolean}
 *
 * @example
 * matchStringOrArray('GET')('DELETE') // false
 * matchStringOrArray([ 'PUT', 'POST' ])('PUT') // true
 * matchStringOrArray()('PUT') // true
 */
const matchStringOrArray = (subject) =>
	!subject
		? () => true // if the subject is undefined, then assume it means to match all options
		: Array.isArray(subject)
		? (val) => subject.includes(val)
		: (val) => subject === val;

const getInterceptIgnoreList = () => [
	{
		method: "POST",
		url: '/batches/:id/images/upload?crc=:crc',
		statusCode: 500
	},
	{
		method: "GET",
		url: "organizations/:id/users",
		statusCode: 401,
	},
].map(({ method, url, statusCode }) => {
	const completeURL = new URL(url, GlobalService.databaseApiUrl);
	return ( {
		method: matchStringOrArray( method ),
		url: createRouteMatcher( completeURL.href ),
		statusCode: matchStringOrArray( statusCode )
	} );
});

/*
 * This is a list of routes in the app where we should not display the error notification.
 * For example, if a customer is on the registration page, we should not be displaying the 401 message.
 */
const getRouteIgnoreList = () => [
	{
		url: "login",
		statusCode: [401],
	},
	{
		url: "forgot",
		statusCode: [401],
	},
	{
		url: "register",
		statusCode: [401],
	},
	{
		url: "register/joinorganization",
		statusCode: [401],
	},
	{
		url: "verification",
		statusCode: [401],
	},
	{
		url: "support",
		statusCode: [401],
	},
	{
		url: "support/:issue",
		statusCode: [401],
	},
	{
		url: "404",
		statusCode: [401],
	},
	{
		url: "",
		statusCode: [401],
	},
].map( ({ url, statusCode }) => ({
	url: createRouteMatcher('/' + url),
	statusCode: matchStringOrArray(statusCode)
}) )

/**
 *
 * @param {Request} req
 * @param {Response} res
 * @param {string} url
 * @returns boolean
 */
const shouldInterceptorSkip = (req, res, url) =>
	getRouteIgnoreList().some(
		(matchers) => matchers.url(url) && matchers.statusCode(res.status)
	) ||
	getInterceptIgnoreList().some(
		(matchers) =>
			matchers.method(req.method) &&
			matchers.url(req.url) &&
			matchers.statusCode(res.status)
	);


@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
	// tslint:disable-next-line:variable-name
	constructor(
		private _alertService: AlertService,
		private _router: Router,
	) {}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// All HTTP requests are going to go through this method
		//TODO: resolve when `next` is the `plan.interceptor`
		if (next.handle(req)) {
			return next.handle(req).pipe(
				// courtesy of https://medium.com/@satyapriyamishra111/angular-error-interceptor-4b102f938065
				catchError((res: HttpErrorResponse) => {
					const isClientSideError = res.error instanceof ErrorEvent;
					if (!shouldInterceptorSkip(req, res, this._router.routerState.snapshot.url) && !isClientSideError) {
						this.showErrorMessage(res)
					}

					return throwError(res);
				})
			);
		}
		// Handle if "handle(req)" has no response
		return new Observable();
	}


	showErrorMessage(res: HttpErrorResponse): void {

		// If you have found yourself here, welcome.
		// I presume you are here because the alert is showing up and you do not want it to
		// or you want to do something similar with another error.
		// If this is the case let me know, I have existing plans for an organized extensions/restriction of these alerts
		// - Danny
		if (res.status === 400) {
			this._alertService.notify(
				'Sorry, there was a bad request.',
				'error'
			);
			console.error('400: BAD REQUEST');
		}
		if (res.status === 401) {
			this._alertService.notify(
				'Sorry, you are not authorized to make this change.',
				'error'
			);
			console.error('401: NOT AUTHORIZED');
		}
		if (res.status === 403) {
			this._alertService.notify(
				'Sorry, your are forbidden from access.',
				'error'
			);
			console.error('403: FORBIDDEN');
		}
		if (res.status === 404) {
			console.error('404: NOT FOUND');
		}
		if (res.status === 412) {
			console.error('412: PRECONDITION FAILED');
		}
		if (res.status === 500) {
			this._alertService.notify(
				'Sorry, our server produced an error.',
				'error'
			);
			console.error('500: INTERNAL SERVER ERROR');
		}
		console.error(res.message)
	}
}
