import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";

import { ErrorFallback } from "@components/ErrorFallback";
import { Annotations } from "@features/Annotations";
import { SidebarPortal } from "@features/Map";

import { store } from "../store";

import { Portal } from "./components";
import { theme } from "./theme";

const Wizard = () => <h1>test</h1>;

function App() {
	return (
		<ErrorBoundary
			FallbackComponent={ErrorFallback}
			onError={console.error}
		>
			<SidebarPortal />
			<Portal selector="ml-wizard">
				<Wizard />
			</Portal>
			<Portal selector="annotations">
				<Annotations />
			</Portal>
		</ErrorBoundary>
	);
}

// Initialize the msw worker, wait for the service worker registration to resolve, then mount
export const startReact = () => {
	ReactDOM.createRoot(document.querySelector("#react-root")).render(
		<React.StrictMode>
			<Provider store={store}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<ErrorBoundary
							FallbackComponent={ErrorFallback}
							onError={console.error}
						>
							<App />
						</ErrorBoundary>
					</ThemeProvider>
				</StyledEngineProvider>
			</Provider>
		</React.StrictMode>
	);
};
