<aerial-floating-bar *ngIf="alert && displayFloatingBar" (closeBar)="displayFloatingBar = false;">
	<div header class="bar-content-inner">
		<span class="bar-status" *ngIf="alert.icon">
			<mat-icon>{{alert.icon}}</mat-icon>
		</span>
		<ng-container *ngIf="isSimpleAlert; else complexAlert">
			<span class="bar-text">
				{{alert.message}}
			</span>
		</ng-container>
	</div>
</aerial-floating-bar>

<ng-template #complexAlert>
	<span class="bar-text">
		<span *ngFor="let item of alert.message">
			<ng-container *ngIf="!item.isLink">
				{{item.text}}
			</ng-container>
			<ng-container *ngIf="item.isLink">
				<span [routerLink]="item.linkRoute" [queryParams]="item.queryParams">
					<u>{{item.text}}</u>
				</span>
			</ng-container>
		</span>
	</span>
</ng-template>
