
<aerial-floating-bar *ngIf="((processList && processList.length > 0) || (completedList && completedList.length > 0)) && displayFloatingBar" (closeBar)="displayFloatingBar = false;">
	<div header class="bar-content-inner">
		<span class="bar-status">
			<div *ngIf="processList.length > 0" class="spinning-loader"></div>
			<mat-icon *ngIf="processList.length <= 0">check</mat-icon>
		</span>
		<span class="bar-text" *ngIf="processList.length > 0">Processing ({{processList.length}})</span>
		<span class="bar-text" *ngIf="processList.length <= 0">All models processed</span>
	</div>
	<div body class="bar-body">
		<mat-list class="bar-list">
			<mat-list-item class="bar-list-item" *ngFor="let process of processList">
				<div class="details">
					<div class="details-title-container">
						<span class="details-title text-link" (click)="openProject(process)" matTooltip="Open in project">{{process && process.name ? process.name : '---'}}</span>
						<span class="processed-text" *ngIf="process.user">(Processed by {{process.user}})</span>
					</div>
					<div class="H7Black700Regular">
						<span>Model processing</span>
						<span class="H7Black500Regular" style="margin-left: 8px;">({{process.status ? process.status : 'Queued'}})</span>
					</div>
				</div>
			</mat-list-item>
			<mat-list-item style="display: block; height: 100%;" *ngFor="let process of completedList" (click)="openProject(process)">
				<div style="display: block; padding: 24px; max-width: calc(100% - 48px);">
					<div class="H6Blue500Regular" style="text-decoration: underline; margin-right: auto; margin-bottom: 8px;">{{process && process.name ? process.name : '---'}}</div>
					<div class="H7Black700Regular">
						<span>Model processed</span>
						<span class="H7Black500Regular" style="margin-left: 8px;">({{process.status}})</span>
					</div>
				</div>
			</mat-list-item>
		</mat-list>
	</div>
</aerial-floating-bar>
