import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService, ModelService, ProjectService, SessionService, SelectedProjectService } from '../../services';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import {uniqBy} from "lodash";

@Component({
	selector: 'app-process-directive',
	templateUrl: 'process.directive.html'
})
export class ProcessDirective implements OnInit, OnDestroy {

	public displayFloatingBar: boolean = false;
	public processList: Array<any> = [];
	public completedList: Array<any> = [];
	public numberCompleted: number = 0;
	private sub: Subscription;
	public isLoggedIn: boolean = true;

	public liveStatus: boolean = false;

	constructor(
		private _modelService: ModelService,
		private _router: Router,
		private _cdr: ChangeDetectorRef,
		private _authService: AuthenticationService,
		private _selectedProject: SelectedProjectService,
		private _projectService: ProjectService,
	) {
	}

	ngOnInit() {
		this.sub = this._modelService.getProcess().subscribe((id: any) => {
			if (id) {
				this.displayFloatingBar = true;
				this._modelService.getById(id).then(rtnModel => {
					this.processList.push(rtnModel);
					this.updateProcessList(this.processList);
					if (!this.liveStatus) {
						this.checkLiveStatus();
					}
				}).catch(console.error)
			}
		});
		this.getProcessList().then(rtnList => {
			this._modelService.setProcessList(rtnList);
			this.updateProcessList(rtnList);
			this.displayFloatingBar = true;
			this.checkLiveStatus();
		});

		this._router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				let user = this._authService.user;
				this.isLoggedIn = (user && user.id);
			}
		});
	}

	ngOnDestroy() {
		if (this.sub) { this.sub.unsubscribe(); }
	}

	getProcessList(): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {
			this._modelService.getProcessingList().then(rtnList => {
				resolve(rtnList);
			}, err => {
				console.error(err)
			})
		})
	}

	updateProcessList(list: Array<any> = []) {
		this.processList = uniqBy([].concat(list), 'id')
	}

	checkLiveStatus(): void {
		this.liveStatus = true;
		setTimeout(() => {
			this.getProcessList().then(rtnList => {
				if (this.processList.length && rtnList.length != this.processList.length) {
					this.getCompleted(rtnList, this.processList);
				}
				this._modelService.setProcessList(rtnList);
				this.updateProcessList(rtnList);
			});
			this._cdr.detectChanges();
			if (this.isLoggedIn) {
				this.checkLiveStatus();
			}
		}, 30000);
	}

	getCompleted(newList: Array<any>, oldList: Array<any>): void {
		if (newList?.length) {
			oldList.forEach(item => {
				let ind = newList?.findIndex(x => x.id === item.id);
				if (ind < 0) {
					item.status = 'Rendered';
					this.completedList.push(item)
				}
			})
		}
	}

	openProject(process): void {
		this._projectService.getById(process.project_id).then(project => {
			SessionService.set('project_tab_index', 0);
			this._selectedProject.setSelectedProject(project);
			this._router.navigateByUrl('/projects/view/' + project.id);
		})
	}
}
