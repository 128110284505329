
/* Imports */
import { Component, OnInit, Input } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

/* Services */
import { AuthenticationService, AlertService } from '../../shared/services';
import { Alert } from '../../shared/models';

enum CARD_SLIDES {
	recover = "recover",
	sent = "sent",
	reset = "reset",
	changed = "changed"
}

@Component({
	selector: 'app-forgot',
	templateUrl: './forgot.component.html',
	styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

	public loading: boolean = false;
	public showInvalid: boolean = false;

	public recoverPasswordFormGroup: FormGroup;
	public resetPasswordFormGroup: FormGroup;
	public activeSlide: CARD_SLIDES = CARD_SLIDES.recover;
	
	public temp_email: string;
	private _token: string;

	get email() {
		return this.recoverPasswordFormGroup.get('email');
	}

	constructor(
		private _authenticationService: AuthenticationService,
		private _alertService: AlertService,
		private _formBuilder: FormBuilder,
		private _router: Router,
		private _route: ActivatedRoute,
		) {

		this.setupPasswordRecoverForm();
		this.setupPasswordResetForm();

	}	// End-of constructor

	ngOnInit() {

		this._route.queryParams.subscribe(params => {
			this._token = params['token'];

			if (this._token) {
				this.activeSlide = CARD_SLIDES.reset;
			}
		});

	}	// End-of OnInit

	setupPasswordRecoverForm(): void {

		this.recoverPasswordFormGroup = this._formBuilder.group({
			email: ['', [Validators.required, Validators.email]],
		});

	}	// End-of setupPasswordRecoverForm

	setupPasswordResetForm(): void {

		this.resetPasswordFormGroup = this._formBuilder.group({
			password: ['', [Validators.required, Validators.minLength(8)]],
			password_verfication: ['', [Validators.required, Validators.minLength(8)]],
		}, {validator: this.checkPasswords });

	}	// End-of setupPasswordResetForm

	appRecover(): void {
		this.loading = true;
		this._authenticationService.recoverPassword(this.recoverPasswordFormGroup.value.email).then(rtn => {
			this.activeSlide = CARD_SLIDES.sent;
			this.loading = false;
		}, error => {
			this.loading = false;
			this.showInvalid = true;
			console.warn("Error recovering password: ", error);
		});

	}	// End-of appRecover

	appReset(): void {

		this.loading = true;
		this._authenticationService.resetPassword(this.resetPasswordFormGroup.value.password, this._token).then(rtn => {
			this.activeSlide = CARD_SLIDES.changed;
			this.loading = false;
		}, error => {
			this.loading = false;
			if (error.error.error == "token already used") {
				this._alertService.error(new Alert("Token already used. Please submit another password change request", 'close', 1200000))
			}
			console.warn("Error resetting password: ", error);
		});

	}	// End-of appReset

	goTo(url) {

		this._router.navigate([url]);

	}	// End-of goTo

	checkPasswords(group: FormGroup): any {

		let pass = group.get('password').value;
		let pass_verified = group.get('password_verfication').value;
		return pass === pass_verified ? null : {notSame: true};

	}	// End-of checkPasswords

}	// End-of class ForgotComponent