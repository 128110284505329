
/* Imports */
import { Component, OnInit, OnDestroy, Inject, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

/* RxJs */
import { Subscription } from 'rxjs';

/* Services */
import { AuthenticationService, UserService, AlertService } from '../../shared/services';

/* Models */
import { User, Alert, Verification } from '../../shared/models';

import _ from "lodash";
import { environment } from "@/environments/environment";

@Component({
	selector: 'app-verification',
	templateUrl: './verification.component.html',
	styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit, OnDestroy {

	public isRegistered: boolean = false;
	public isProcessing: boolean = false;
	public showVerificationError: boolean = false;
	public showVerifiedNoUser: boolean = false;
	public user: User;
	public verificationData: Verification = null;
	public homeUrl = "app.mapware.io";
	public freeCredits: number = environment.freeCredits;

	public userSubscription: Subscription;
	public routeSubscription: Subscription;

	public verificationOptions = {
		notVerified: 		"notVerified",
		verified: 			"verified",
		verificationError: 	"verificationError"
	}

	constructor (
		private _authenticationService: AuthenticationService,
		private _userService: UserService,
		private _activedRoute: ActivatedRoute,
		private _router: Router,
		private _changeDetectorRef: ChangeDetectorRef,
		private _ngZone: NgZone,
		private _alertService: AlertService
		) {

		this.routeSubscription = this._activedRoute.queryParams.subscribe(params => {
			const verification_id = params['id'];

			if (!_.isNil(verification_id)) {
				this.verifyAccount(verification_id);
			}
		});


	}	// End-of constructor

	ngOnInit() {

		this.userSubscription = this._authenticationService.userState.state.subscribe(user => {
			this.user = user?.id ? user : null; // If no id, set to null for html logic

			// Re-route if open in another tab and the state changes, with verification
			if (!this.verificationData && user?.email_verified)
				this._router.navigateByUrl("/");
		});

	}	// End-of OnInit

	ngOnDestroy() {

		if (this.userSubscription) {
			this.userSubscription.unsubscribe();
		}

		if (this.routeSubscription) {
			this.routeSubscription.unsubscribe();
		}

	}	// End-of ngOnDestroy

	verifyAccount = (verification_id) => {
		this._authenticationService.verify(verification_id).then(rtn => {
			this._authenticationService.getUser()
				.then(this._authenticationService.updateUser);
			this.verificationData = rtn;
			this._alertService.success(new Alert("Success! Your account has been verified."));
		}, error => {
			if (error === 412) {
				console.warn( "Account already exists" );

				// If verified already and logged in, navigate
				if (this.user?.email_verified) {
					this._alertService.notification( new Alert( "Your email has already been verified, navigating to Mapware." ) );
					this.navigateToMapware();
				}
				// If verification already occurred but existing user is not verified, determine if user exists
				else {
					this._alertService.notification( new Alert( "Your email has already been verified." ) );
					this.showVerifiedNoUser = !this.user?.id;
				}
			} else {
				this.showVerificationError = true;
				this._alertService.notification(new Alert('Verification Failed'));
				console.error("Verification Failed: ", error);
			}
		});
	}

	navigateToMapware = () =>
		this._router.navigateByUrl('/');

	verificationStatus(): boolean {

		let user = this._authenticationService.user;
		return (user && user.id != undefined && user.email_verified === 1);

	}	// End-of getVerificationStatus

	resendEmail(): void {

		let user = this._authenticationService.user;
		if (user && user.id != undefined) {
			this._alertService.notification(new Alert('Verification Email Sent'));
			this._authenticationService.resendVerification(user.email);
		}

	}	// End-of resendEmail

	logout(): void {

		this._authenticationService.logout().then(() => {
			this._router.navigate(['login']);
		});

	}	// End-of logout

	showVerification(verificationData, showVerifiedNoUser, showVerificationError): string {
		const options = this.verificationOptions;
		const _default = true as any;
		const matcher = {
			[_default]: 											options.notVerified,
			[(!!verificationData || !!showVerifiedNoUser) as any]: 	options.verified,
			[(!!showVerificationError) as any]: 					options.verificationError
		}
		return matcher[_default];
	}

}	// End-of class RegisterComponent
