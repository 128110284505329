
/* Imports */
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, NgZone } from '@angular/core';
import { environment } from '@/environments/environment';
import { Router, NavigationEnd } from '@angular/router';

/* Services */
import {  TitleService, AuthenticationService } from '../../shared/services';

const routeSpecificMessaging = {
	default: {
		errorCode: null,
		pageTitle: 'Expired link',
		supportTitle: "Something Went Wrong",
		supportText: "If you continue arriving at this page, please",
		logoutText: "Back to login",
		homeText: null,
		showSupportLink: true
	},
	no_sub: {
		errorCode:"Account organizations do not have attached Subscriptions.",
		pageTitle: 'Expired link',
		supportTitle: "Subscription Support Needed",
		supportText: "Your account's subscription has an unexpected issue, please",
		logoutText: "Back to login",
		homeText: null,
		showSupportLink: true
	},
	no_org: {
		errorCode:"Your account does not have an organization.",
		pageTitle: 'Expired link',
		supportTitle: "Organization Support Needed",
		supportText: "Your account's organization has an unexpected issue, please",
		logoutText: "Back to login",
		homeText: null,
		showSupportLink: true
	},
	no_invite: {
		errorCode: null,
		pageTitle: 'Expired link',
		supportTitle: "Invite Link Expired",
		supportText: "The link provided in your invitation email has expired. Please contact the organization's admin or",
		logoutText: "Back to login",
		homeText: null,
		showSupportLink: true
	},
	maintenance: {
		errorCode: null,
		pageTitle: 'Under Maintenance',
		supportTitle: "Mapware is currently down for maintenance...",
		supportText: "We will be back online as soon as possible. Thank you for your patience!",
		logoutText: null,
		homeText: null,
		showSupportLink: false
	},
	map: {
		errorCode: null,
		pageTitle: 'Under Maintenance',
		supportTitle: "The Map is currently down for maintenance...",
		supportText: "We will be back online as soon as possible. Thank you for your patience!",
		logoutText: null,
		homeText: "Return home",
		showSupportLink: false
	},
}

const getSupportURLDetail = (supportStr) =>  supportStr.replace(/(\S+support\/?)(\w+)/, '$2');

const checkRouteDetails = (urlDetail) => Object.keys(routeSpecificMessaging).some(x => x == urlDetail);

@Component({
	selector: 'app-notfound',
	templateUrl: './notfound.component.html',
	styleUrls: ['./notfound.component.scss']
})
export class NotFoundComponent implements OnInit {

	public routeMessaging = routeSpecificMessaging.default;

	constructor(
		private _titleService: TitleService,
		private _router: Router,
		private _ngZone: NgZone,
		private _authenticationService: AuthenticationService,
		private _cdr: ChangeDetectorRef
	) {

		this._router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (event.url.includes('support')) {
					const urlDetail = getSupportURLDetail(event.url);
					this.routeMessaging = checkRouteDetails(urlDetail) ? routeSpecificMessaging[urlDetail] : routeSpecificMessaging.default;
					this._titleService.setTitle(this.routeMessaging.pageTitle);
					this._cdr.detectChanges();
				} else if (event.url.includes('map')) {
					this.routeMessaging = routeSpecificMessaging.map;
					this._titleService.setTitle(this.routeMessaging.pageTitle);
					this._cdr.detectChanges();
				}
			} else {
				this._titleService.setTitle('Page Not Found');
			}
		});

	}	// End-of constructor

	ngOnInit() {
	}	// End-of OnInit

	sendFeedback(): void {
		window.open(`mailto:${environment.supportEmail}?subject=${this.routeMessaging.supportTitle}&body=${this.routeMessaging.errorCode}`);
	}

	logout(): void {

		this._authenticationService.logout().then(() => {
			this._router.navigate(['login']);
		});

	}	// End-of logout

	returnHome(): void {
		this._router.navigate(['']);
	}

}	// End-of class LoginComponent
