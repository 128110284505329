<aerial-floating-bar
	*ngIf="isLoggedIn && displayFloatingBar"
	(closeBar)="displayFloatingBar = false"
	[hideDismiss]="isUploading"
	data-testid="upload-alert-bar"
>
	<div header class="bar-content-inner">
		<span class="bar-status">
			<ng-container *ngIf="!renderState.status.allPaused">
				<div *ngIf="renderState.status.anyActive" class="spinning-loader"></div>
				<mat-icon *ngIf="!renderState.status.anyActive">check</mat-icon>
			</ng-container>
			<mat-icon *ngIf="renderState.status.allPaused">pause</mat-icon>
		</span>
		<span class="bar-text" *ngIf="renderState.status.anyActive || renderState.status.anyPaused">
			Uploading ({{ renderState.projectList.length }})
			<span *ngIf="renderState.status.anyPaused">
				, {{projectsPaused(renderState)}} paused
			</span>
		</span>

		<span class="bar-text" *ngIf="!renderState.status.anyActive && !renderState.status.anyPaused">
			{{renderState.uploadIssue?.length ? 'Upload complete, but ' + projectsWithErrors(renderState) + ' had an issue' : 'All files uploaded'}}
		</span>

	</div>
	<div body class="bar-body">
		<mat-list class="bar-list">
			<mat-list-item
				class="bar-list-item"
				*ngFor="let project of renderState.projectList; trackBy: useProjectId">

				<div class="details">
					<div
						class="details-title"
						(click)="openProject(project)"
						matTooltip="Open project">

						{{ project && project.name ? project.name : "---" }}
					</div>
					<div class="H7Black700Regular" *ngIf="!project.queued">
						<span data-testid="upload-progress-count" *ngIf="!project.finished">
							Uploaded {{ project.completedFiles }} of {{ project.totalFiles }} files
						</span>

						<span data-testid="upload-progress-complete" *ngIf="project.finished">
							<ng-container>
								{{project.hasError ? failedImagesText(project) : 'All files uploaded'}}
							</ng-container>

						</span>
						<span
							*ngIf="!(project.finished || project.status === 'CANCELED')"
							class="H7Black500Regular"
							style="margin-left: 8px">
								({{
								project.percentageComplete > 100
									? 100
									: project.percentageComplete
								}}% complete)
						</span>
						<span
							*ngIf="project.status === 'CANCELED'"
							class="H7Black500Regular"
							style="margin-left: 8px">
								(Upload stopped)
						</span>
					</div>
					<div class="H7Black700Regular" *ngIf="project.queued">
						<span>Queued ({{ project.numberTotal }} files)</span>
					</div>
					<span class="H7Black500Regular" *ngIf="!project.finished && project.hasError">
						Some files had an issue uploading. A full list will be available once uploading has finished.
					</span>
					<span class="H7Black500Regular" *ngIf="project.finished && project.hasError">
						{{project.failedFileNames}} failed to upload, please try to upload just those files again.
					</span>
				</div>
				<span class="icon-container" *ngIf="!project.finished">
					<div
						class="progress-stop"
						*ngIf="!project.finished && project.status === 'PAUSED' && !project.stopped"
						matTooltip="Stop upload"
						(click)="
						markProjectCanceled(project.name, project.id);
						$event.stopPropagation()
					">

					<div class="stop">
						<mat-icon>stop</mat-icon>
					</div>
					</div>
					<div class="progress">
					<span
						data-testid="togglePause"
						*ngIf="!(project.finished || project.status === 'CANCELED')"
						[matTooltip]="
						project.stopped
							? ''
							: project.status === 'PAUSED'
							? 'Resume upload'
							: 'Pause upload'
						"
						(click)="togglePause(project.id); $event.stopPropagation()">

						<app-upload-circle [percentage]="project.percentageComplete"></app-upload-circle>

						<div class="pause" *ngIf="project.status !== 'PAUSED'">
							<mat-icon>pause</mat-icon>
						</div>
						<div class="resume" *ngIf="project.status === 'PAUSED'">
							<mat-icon>play_arrow</mat-icon>
						</div>
					</span>
					</div>
				</span>
				<span class="icon-container" *ngIf="project.finished">
					<mat-icon class="complete" *ngIf="project.finished">
						check_circle
					</mat-icon>
				</span>
			</mat-list-item>
		</mat-list>
	</div>
</aerial-floating-bar>
