/* Import */
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { flagLayer, availableFeatureFlags } from "@shared/featureFlags";

/* Pages */
import * as Annotations from "./components/annotations";
import * as Projects from "./pages/projects";
import * as NotFound from "./pages/notfound";
import * as Verification from "./pages/verification";
import * as Login from "./pages/login";
import * as Forgot from "./pages/forgot";
import * as Main from "./pages/main/main.component";

/* Guards */
import * as Guards from "./shared/guards";

const routes: Routes = [
	{
		path: "login",
		canActivate: [ Guards.UserGuard, Guards.MaintenanceGuard ],
		component: Login.LoginComponent
	},
	{
		path: "forgot",
		component: Forgot.ForgotComponent,
		canActivate: [ Guards.MaintenanceGuard ]
	},
	{
		path: "register/joinorganization",
		loadChildren: () =>
			import("./pages/register/register.module").then(
				( m ) => m.RegisterModule
			),
		canActivate: [ Guards.UserGuard, Guards.MaintenanceGuard ]
	},
	{
		path: "register",
		loadChildren: () =>
			import("./pages/register/register.module").then(
				( m ) => m.RegisterModule
			),
		canActivate: [ Guards.UserGuard, Guards.MaintenanceGuard ]
	},
	{
		path: "verification",
		component: Verification.VerificationComponent,
		canActivate: [ Guards.MaintenanceGuard ]
	},
	{
		path: "unsubscribe",
		component: Main.MainComponent,
		children: [
			{
				path: "",
				component: Projects.ListProjectsComponent
			}
		],
		canActivate: [ Guards.UserGuard, Guards.MaintenanceGuard ]
	},
	{
		path: "v", // Public route for viewer
		loadChildren: () =>
			import("./pages/map/map.module").then(
				( m ) => m.MapModule
			),
		canActivate: [ Guards.MaintenanceGuard ]
	},
	{
		path: "map/:project",
		loadChildren: () =>
			import("./pages/map/map.module").then(
				( m ) => m.MapModule
			),
		canActivate: [ Guards.UserGuard, Guards.MaintenanceGuard ]
	},
	{
		path: "projects/process",
		loadChildren: () =>
			import("./pages/process/process.module").then(
				( m ) => m.ProcessModule
			),
		canActivate: [ Guards.UserGuard, Guards.MaintenanceGuard ]
	},
	{
		path: "projects/import",
		loadChildren: () =>
			import("./pages/process/process.module").then(
				( m ) => m.ProcessModule
			),
		canActivate: [ Guards.UserGuard, Guards.MaintenanceGuard ]
	},
	{
		path: "photos/:batch_id/:guid",
		component: Projects.PhotosViewComponent,
		canActivate: [ Guards.MaintenanceGuard ]
	},
	{
		path: "404",
		component: NotFound.NotFoundComponent,
		canActivate: [ Guards.MaintenanceGuard ]
	},
	{
		path: "support",
		component: NotFound.NotFoundComponent,
		canActivate: [ Guards.MaintenanceGuard ]
	},
	{
		path: "support/:id",
		component: NotFound.NotFoundComponent,
		canActivate: [ Guards.MaintenanceGuard ]
	},
	{
		path: "annotations",
		component: Annotations.AnnotationsComponent,
		canActivate: [ Guards.UserGuard, Guards.MaintenanceGuard ]
	},
	{
		path: "",
		loadChildren: () =>
			import("./pages/main/main.module").then( ( m ) => m.MainModule ),
		canActivate: [ Guards.MaintenanceGuard ]
	},
	{
		path: "**",
		redirectTo: "/404"
	} // End-of path **
]; // End-of routes

@NgModule( {
	imports: [ RouterModule.forRoot( routes, { useHash: true } ) ],
	exports: [ RouterModule ]
} )
export class AppRoutingModule {
}
