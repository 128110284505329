import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable, of, from} from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import {environment} from "@/environments/environment";
import { OrganizationService } from "@shared/services/organization.service";
import { _Subscription } from '@shared/services/subscription.service'

const isPlanReq = ({ url }) => url.includes('plans') && !url.includes('chargebee');
const isGetReq = ({ method }) => method.toLowerCase() === 'get';
const shouldAlert = (req) => !isGetReq(req) && isPlanReq(req);
const shouldRewire = (req) => isGetReq(req) && isPlanReq(req)
const shouldGetById = ({ url }) => url.includes('/organizations/')
const getOrgId = ({ url }) => url.match(/\/organizations\/(?<orgId>\d+)/)?.groups.orgId
@Injectable()
export class PlanInterceptor implements HttpInterceptor {
	// tslint:disable-next-line:variable-name
	constructor(
		private _alertService: AlertService,
		private _orgService: OrganizationService
	) {}

	somethingWentWrong() {
		this._alertService.notify(
			'Sorry, something went wrong please reload the page.',
			'error'
		);
	}

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// All HTTP requests are going to go through this method
		try {
			if (shouldAlert(req)) {
				this._alertService.notify(`
					We’re renovating our online billing to make it faster and easier.
					While that’s underway, please contact our sales team at ${environment.supportEmail}
				`.trim().replace(/^\s+/gm, ''));
				return of(new HttpResponse({ status: 404 }));
			} else if (shouldRewire(req)) {
				if (shouldGetById(req)) {
					return from(this._orgService.getById(getOrgId(req))
						.then(_Subscription.toAerialPlan)
						.then( plan => {
							if (!plan) throw `no subscription on org: ${getOrgId(req)}`
							else return plan
						} )
						.then(plan => new HttpResponse({status: 200, body: [plan]}))
						.catch(e => {
							console.error(e)
							// this.somethingWentWrong()
							return new HttpResponse({status: 500})
						}))
				}
			} else {
				return next.handle(req)
			}
		} catch(e) {
			console.error(e);
			this.somethingWentWrong()
		}
	}
}
